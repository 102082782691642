<template>
  <div v-if='LOGGED_IN' id='page' class='d-flex' style='position:absolute;'>

      <div class='fill-height overflow-auto px-2' style='flex:0 0 220px'>
        <template v-for="(s,i) in settings">
          <router-link
            v-bind:key="i"
            v-if='module_codes.includes(s.perm) || !s.perm'
            :to='s.route'
            class='menuItem mb-2 py-2 titlefont'
            >
            <v-icon :class='s.icon'>{{s.icon}}</v-icon> {{s.title}}
          </router-link>
        </template>
      </div>

      <router-view style='flex:1 0 600px' />

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/common";
#page{
  -background-color: yellow;
  height: 100%;
  max-height:100%;
  width:100%;
  overflow: hidden;
  .row{ height: 100%; }
}

.menuItem{
  display: block;
  text-decoration: none;
  color: $secondary;
  font-weight: normal;
  border-radius: 6px;
  .v-icon{
    width: 50px;
  }
  &.router-link-active{
    background-color: $primary;
    color: white;
    .v-icon{ color: white; }
    &:hover{
      background-color: lighten($primary, 2%);
      color: white;
      .v-icon{ color: white; }
    }
  }
  &:hover{
    background-color: rgba($primary, 15%);
    color: $primary;
    .v-icon{ color: $primary; }
  }
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: '',

  components: {
  },

  data: () => ({
    settings: [
      { title: "Profile", icon: 'mdi-account', route: "/settings/profile" },
      { title: "Permissions", icon: 'mdi-monitor-dashboard', perm: "module_group_controls", route: "/settings/permissions" },
      { title: "Accounts", icon: 'mdi-domain', perm: "admin_accounts", route: "/settings/accounts" },
      { title: "Users", icon: 'mdi-account-circle', perm: "manage_users", route: "/settings/users" },
      { title: "Jobs", icon: 'mdi-briefcase', perm: "job_settings", route: "/settings/jobs" },
      { title: "Job Statuses", icon: 'mdi-state-machine', perm: "job_settings", route: "/settings/job_status" },
      { title: "Price Tiers", icon: 'mdi-currency-usd', perm: "billing_settings", route: "/settings/tiers" },
      { title: "Products", icon: 'mdi-barcode', perm: "billing_settings", route: "/settings/products" },
      { title: "Taxes", icon: 'lcc-tax', perm: "billing_settings", route: "/settings/taxes" },
      { title: "Prescribe", icon: '$lasercam', perm: null, route: "/settings/prescribe" },
      { title: "QMS", icon: 'mdi-medal-outline', perm: null, route: "/settings/qms" },
      { title: "Workflow", icon: 'mdi-transit-connection-horizontal', perm: null, route: "/settings/workflow" },
      { title: "Printers", icon: 'mdi-printer', perm: null, route: "/settings/print" },
      { title: "Dispatch", icon: 'mdi-truck', perm: null, route: "/settings/dispatch" },
      { title: "Invoices", icon: '$invoice', perm: null, route: "/settings/invoice" },
    ]
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN', 'MODULES']),
    module_codes(){
      return this.MODULES.map(m => m.code);
    },
  },

  methods:{
    ...mapActions('UI', ['SET_PAGE_TITLE', 'SHOW_NAVBARS']),
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
  },

  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS()
      this.SET_PAGE_TITLE('Settings');
    });
  },

};
</script>
