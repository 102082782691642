var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "position": "absolute"
    },
    attrs: {
      "id": "page"
    }
  }, [_c('div', {
    staticClass: "fill-height overflow-auto px-2",
    staticStyle: {
      "flex": "0 0 220px"
    }
  }, [_vm._l(_vm.settings, function (s, i) {
    return [_vm.module_codes.includes(s.perm) || !s.perm ? _c('router-link', {
      key: i,
      staticClass: "menuItem mb-2 py-2 titlefont",
      attrs: {
        "to": s.route
      }
    }, [_c('v-icon', {
      class: s.icon
    }, [_vm._v(_vm._s(s.icon))]), _vm._v(" " + _vm._s(s.title) + " ")], 1) : _vm._e()];
  })], 2), _c('router-view', {
    staticStyle: {
      "flex": "1 0 600px"
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }